import { FC } from 'react'

export const GithubSvg: FC = () => (
  <svg height='24' width='25'>
    <path
      d='M12.5 0C5.598 0 0 5.509 0 12.303c0 5.436 3.581 10.048 8.549 11.675.624.114.826-.267.826-.591v-2.29c-3.477.744-4.201-1.453-4.201-1.453-.569-1.422-1.389-1.8-1.389-1.8-1.134-.764.087-.747.087-.747 1.255.086 1.915 1.268 1.915 1.268 1.115 1.88 2.924 1.337 3.638 1.022.111-.795.435-1.338.794-1.645-2.776-.312-5.695-1.367-5.695-6.08 0-1.345.489-2.442 1.287-3.303-.129-.31-.557-1.562.122-3.256 0 0 1.05-.33 3.439 1.26.997-.272 2.066-.408 3.128-.413 1.063.005 2.132.141 3.131.414 2.387-1.591 3.435-1.261 3.435-1.261.68 1.695.252 2.946.123 3.256.802.861 1.286 1.96 1.286 3.303 0 4.725-2.924 5.766-5.707 6.07.448.382.857 1.13.857 2.278v3.377c0 .327.2.711.834.59C21.423 22.348 25 17.737 25 12.303 25 5.51 19.403 0 12.5 0Z'
      fill='#05080D'
      fillRule='nonzero'
    />
  </svg>
)
